import { ReactComponent as AccessDenied } from 'icons/access-denied.svg'
import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ReactComponent as Bin } from 'icons/bin.svg'
import { ReactComponent as Bold } from 'icons/bold.svg'
import { ReactComponent as Bubbles } from 'icons/bubbles.svg'
import { ReactComponent as BulbInCircle } from 'icons/bulb-in-circle.svg'
import { ReactComponent as Burger } from 'icons/burger.svg'
import { ReactComponent as CalendarFilled } from 'icons/calendar-filled.svg'
import { ReactComponent as Calendar } from 'icons/calendar.svg'
import { ReactComponent as Call } from 'icons/call.svg'
import { ReactComponent as CheckboxCheckedFilled } from 'icons/checkbox-checked-filled.svg'
import { ReactComponent as CheckboxUnchecked } from 'icons/checkbox-unchecked.svg'
import { ReactComponent as CheckmarkInCircleFilled } from 'icons/checkmark-in-circle-filled.svg'
import { ReactComponent as CheckmarkInCircle } from 'icons/checkmark-in-circle.svg'
import { ReactComponent as CheckmarkInDoubleCircleFilled } from 'icons/checkmark-in-double-circle-filled.svg'
import { ReactComponent as Checkmark } from 'icons/checkmark.svg'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ReactComponent as Circle } from 'icons/circle.svg'
import { ReactComponent as DeleteElection } from 'icons/delete-election.svg'
import { ReactComponent as DismissInCircleFilled } from 'icons/dismiss-circle.svg'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { ReactComponent as DocumentAddFilled } from 'icons/document-add-filled.svg'
import { ReactComponent as DocumentAdd } from 'icons/document-add.svg'
import { ReactComponent as Dots } from 'icons/dots.svg'
import { ReactComponent as DownColored } from 'icons/down-colored.svg'
import { ReactComponent as Envelope } from 'icons/envelope.svg'
import { ReactComponent as Eraser } from 'icons/eraser.svg'
import { ReactComponent as ErrorPage } from 'icons/error-page.svg'
import { ReactComponent as Eye } from 'icons/eye.svg'
import { ReactComponent as GearFilled } from 'icons/gear-filled.svg'
import { ReactComponent as Gear } from 'icons/gear.svg'
import { ReactComponent as HomeFilled } from 'icons/home-filled.svg'
import { ReactComponent as Home } from 'icons/home.svg'
import { ReactComponent as InfoRoundedFilled } from 'icons/info-rounded-filled.svg'
import { ReactComponent as InfoRounded } from 'icons/info-rounded.svg'
import { ReactComponent as Interrupt } from 'icons/interrupt.svg'
import { ReactComponent as Italic } from 'icons/italic.svg'
import { ReactComponent as Link } from 'icons/link.svg'
import { ReactComponent as LogoBigSandbox } from 'icons/logo-big-sandbox.svg'
import { ReactComponent as LogoBig } from 'icons/logo-big.svg'
import { ReactComponent as Logo } from 'icons/logo.svg'
import { ReactComponent as Loupe } from 'icons/loupe.svg'
import { ReactComponent as MinusRounded } from 'icons/minus-rounded.svg'
import { ReactComponent as Navigation } from 'icons/navigation.svg'
import { ReactComponent as Pdf } from 'icons/pdf.svg'
import { ReactComponent as Pen } from 'icons/pen.svg'
import { ReactComponent as PeopleCheckmarkFilled } from 'icons/people-checkmark-filled.svg'
import { ReactComponent as PeopleCheckmark } from 'icons/people-checkmark.svg'
import { ReactComponent as PeopleCommunityFilled } from 'icons/people-community-filled.svg'
import { ReactComponent as PeopleCommunity } from 'icons/people-community.svg'
import { ReactComponent as PeopleSettingsFilled } from 'icons/people-settings-filled.svg'
import { ReactComponent as PeopleSettings } from 'icons/people-settings.svg'
import { ReactComponent as PlusRounded } from 'icons/plus-rounded.svg'
import { ReactComponent as Plus } from 'icons/plus.svg'
import { ReactComponent as Preloader } from 'icons/preloader.svg'
import { ReactComponent as Printer } from 'icons/printer.svg'
import { ReactComponent as QuestionMarkInCircle } from 'icons/question-mark-in-circle.svg'
import { ReactComponent as Question } from 'icons/question.svg'
import { ReactComponent as ReportFilled } from 'icons/report-filled.svg'
import { ReactComponent as Report } from 'icons/report.svg'
import { ReactComponent as Reset } from 'icons/reset.svg'
import { ReactComponent as RoundedCircle } from 'icons/rounded-circle.svg'
import { ReactComponent as Save } from 'icons/save.svg'
import { ReactComponent as Search } from 'icons/search.svg'
import { ReactComponent as SignOut } from 'icons/sign-out.svg'
import { ReactComponent as Sorting } from 'icons/sorting.svg'
import { ReactComponent as Strikethrough } from 'icons/strikethrough.svg'
import { ReactComponent as Submenu } from 'icons/submenu.svg'
import { ReactComponent as TextAlignCenter } from 'icons/text-align-center.svg'
import { ReactComponent as TextAlignLeft } from 'icons/text-align-left.svg'
import { ReactComponent as TextAlignRight } from 'icons/text-align-right.svg'
import { ReactComponent as TextJustify } from 'icons/text-justify.svg'
import { ReactComponent as TicketFilled } from 'icons/ticket-filled.svg'
import { ReactComponent as Ticket } from 'icons/ticket.svg'
import { ReactComponent as TriangleSoft } from 'icons/triangle-soft.svg'
import { ReactComponent as Underline } from 'icons/underline.svg'
import { ReactComponent as Undo } from 'icons/undo.svg'
import { ReactComponent as User } from 'icons/user.svg'
import { ReactComponent as VoteFilled } from 'icons/vote-filled.svg'
import { ReactComponent as Vote } from 'icons/vote.svg'
import { ReactComponent as WarningFilled } from 'icons/warning-filled.svg'
import { ReactComponent as Warning } from 'icons/warning.svg'
import { ReactComponent as Xls } from 'icons/xls.svg'

export const iconsList = [
    <span id="PlusRounded"><PlusRounded /></span>,
    <span id="MinusRounded"><MinusRounded /></span>,
    <span id="Bin"><Bin /></span>,
    <span id="Burger"><Burger /></span>,
    <span id="CalendarFilled"><CalendarFilled /></span>,
    <span id="Calendar"><Calendar /></span>,
    <span id="Call"><Call /></span>,
    <span id="CheckmarkInCircleFilled"><CheckmarkInCircleFilled /></span>,
    <span id="CheckmarkInCircle"><CheckmarkInCircle /></span>,
    <span id="Chevron"><Chevron /></span>,
    <span id="ChevronBold"><ChevronBold /></span>,
    <span id="Circle"><Circle /></span>,
    <span id="Dismiss"><Dismiss /></span>,
    <span id="DismissInCircleFilled"><DismissInCircleFilled /></span>,
    <span id="DocumentAddFilled"><DocumentAddFilled /></span>,
    <span id="DocumentAdd"><DocumentAdd /></span>,
    <span id="Dots"><Dots /></span>,
    <span id="DownColored"><DownColored /></span>, //for background-color
    <span id="Envelope"><Envelope /></span>,
    <span id="GearFilled"><GearFilled /></span>,
    <span id="Gear"><Gear /></span>,
    <span id="HomeFilled"><HomeFilled /></span>,
    <span id="Home"><Home /></span>,
    <span id="Navigation"><Navigation /></span>,
    <span id="Pen"><Pen /></span>,
    <span id="PeopleCheckmarkFilled"><PeopleCheckmarkFilled /></span>,
    <span id="PeopleCheckmark"><PeopleCheckmark /></span>,
    <span id="Checkmark"><Checkmark /></span>,
    <span id="PeopleCommunityFilled"><PeopleCommunityFilled /></span>,
    <span id="PeopleCommunity"><PeopleCommunity /></span>,
    <span id="PeopleSettingsFilled"><PeopleSettingsFilled /></span>,
    <span id="PeopleSettings"><PeopleSettings /></span>,
    <span id="Plus"><Plus /></span>,
    <span id="Question"><Question /></span>,
    <span id="QuestionMarkInCircle"><QuestionMarkInCircle /></span>,
    <span id="RoundedCircle"><RoundedCircle /></span>,
    <span id="Reset"><Reset /></span>,
    <span id="Save"><Save /></span>,
    <span id="Search"><Search /></span>,
    <span id="SignOut"><SignOut /></span>,
    <span id="Sorting"><Sorting /></span>,
    <span id="Submenu"><Submenu /></span>,
    <span id="TriangleSoft"><TriangleSoft /></span>,
    <span id="VoteFilled"><VoteFilled /></span>,
    <span id="User"><User /></span>,
    <span id="Vote"><Vote /></span>,
    <span id="ArrowDown"><ArrowDown /></span>,
    <span id="ArrowDownload"><ArrowDownload /></span>,
    <span id="CheckboxCheckedFilled"><CheckboxCheckedFilled /></span>,
    <span id="CheckboxUnchecked"><CheckboxUnchecked /></span>,
    <span id="Loupe"><Loupe /></span>,
    <span id="Preloader"><Preloader /></span>,
    <span id="InfoRounded"><InfoRounded /></span>,
    <span id="InfoRoundedFilled"><InfoRoundedFilled /></span>,
    <span id="Interrupt"><Interrupt /></span>,
    <span id="Eraser"><Eraser /></span>,
    <span id="CheckmarkInDoubleCircleFilled"><CheckmarkInDoubleCircleFilled /></span>,
    <span id="Report"><Report /></span>,
    <span id="Warning"><WarningFilled /></span>,
    <span id="WarningFilled"><Warning /></span>,
    <span id="ReportFilled"><ReportFilled /></span>,
    <span id="Ticket"><Ticket /></span>,
    <span id="TicketFilled"><TicketFilled /></span>,
    <span id="Pdf"><Pdf /></span>,
    <span id="Eye"><Eye /></span>,
    <span id="Printer"><Printer /></span>,
    <span id="BulbInCircle"><BulbInCircle /></span>,
    <span id="Undo"><Undo /></span>,
    <span id="Bold"><Bold /></span>,
    <span id="Italic"><Italic /></span>,
    <span id="Underline"><Underline /></span>,
    <span id="Strikethrough"><Strikethrough /></span>,
    <span id="Link"><Link /></span>,
    <span id="TextAlignLeft"><TextAlignLeft /></span>,
    <span id="TextAlignCenter"><TextAlignCenter /></span>,
    <span id="TextAlignRight"><TextAlignRight /></span>,
    <span id="TextJustify"><TextJustify /></span>,
    <span id="DeleteElection"><DeleteElection /></span>,
    <span id="Xls"><Xls /></span>
]

export const bigIconsList = [
    <span id="LogoBig"><LogoBig /></span>,
    <span id="Logo"><Logo /></span>,
    <span id="Logo"><LogoBigSandbox /></span>,
    <span id="ErrorPage"><ErrorPage /></span>,
    <span id="Bubbles"><Bubbles /></span>,
    <span id="AccessDenied"><AccessDenied /></span>
]