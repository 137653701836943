import { useCheckReportStatus, useGenerateReport, useReportUrl } from 'api'
import { queryClient } from 'config/query-client'
import { ReportFilter, ReportStatus, ReportToLoad } from 'models'
import { useEffect, useState } from 'react'

export const useReportLoader = () => {
    const [reportFilter, setReportFilter] = useState<ReportFilter>()
    const [reportIsReady, setReportIsReady] = useState(false)
    const [timerId, setTimerId] = useState<NodeJS.Timer>()
    const [isReportLoading, setIsReportLoading] = useState(false)
    const [error, setError] = useState<string>()
    const [saveReportUrl, setSaveReportUrl] = useState<(url: string) => void>()

    const { data: reportId, error: generationError } = useGenerateReport(reportFilter || ({} as any), !!reportFilter)
    const { data: status, isLoading: isStatusLoading } = useCheckReportStatus(reportId!, !!reportId)
    const { getReportUrl } = useReportUrl()

    useEffect(() => {
        const resetReportStatusChecking = () => {
            if (timerId) {
                clearInterval(timerId)
            }
            setTimerId(undefined)
            setIsReportLoading(false)
            queryClient.removeQueries(['report',
                reportFilter?.reportType,
                reportFilter?.reportFormat])
            queryClient.removeQueries(['report-status', reportId])
            setReportFilter(undefined)
        }
        if (status) {
            if (status.includes(ReportStatus.FAILED)) {
                setError(status.split(`${ReportStatus.FAILED}: `)[1])
            }
            switch (status) {
                case ReportStatus.PENDING:
                case ReportStatus.IN_PROGRESS:
                    if (!timerId) {
                        setIsReportLoading(true)
                        const tId = setInterval(async () => {
                            await queryClient.invalidateQueries(['report-status', reportId])
                        }, 1000)
                        setTimerId(tId)
                    }
                    break
                case ReportStatus.COMPLETED:
                    if (!reportIsReady) {
                        if (saveReportUrl) {
                            saveReportUrl(getReportUrl(reportId!))
                        } else {
                            const tempLink = document.createElement('a')
                            tempLink.href = getReportUrl(reportId!)
                            tempLink.target = 'blank'
                            tempLink.click()
                        }
                        setReportIsReady(true)
                    }
                    resetReportStatusChecking()
                    break
                default:
                    resetReportStatusChecking()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, isStatusLoading, saveReportUrl])

    useEffect(() => {
        const genError = generationError as any
        if (genError?.message) {
            setError(genError.message + (genError.response?.data || ''))
        } else {
            setError(undefined)
        }
    }, [generationError])

    const loadReport = async ({ reportType, reportFormat, params }: ReportToLoad,
        saveReportUrl?: (url: string) => void) => {
        setSaveReportUrl(() => saveReportUrl)
        setError(undefined)
        setReportIsReady(false)
        setReportFilter({
            reportFormat,
            reportType,
            params
        })
    }

    return { loadReport, isReportLoading, error, reportIsReady }
}