import { Form } from 'antd'
import { useRbcNames } from 'api'
import MultiSelect from 'components/molecules/MultiSelect'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type MultipleRbcFilterProps = {
    disabled?: boolean
}

function MultipleRbcFilter({ disabled }: MultipleRbcFilterProps) {
    const { t } = useTranslation()

    const { data: rbcNames } = useRbcNames(!disabled)

    const options = useMemo(() => {
        if (!rbcNames) return []

        const sortedRbcs = [...rbcNames].sort((a, b) => {
            const rbcA = a.name.toLowerCase()
            const rbcB = b.name.toLowerCase()

            return rbcA.localeCompare(rbcB)
        })

        return sortedRbcs
    }, [rbcNames])

    return (
        <Form.Item
            name="rbc"
            className="no-padding"
            label={t('election:institution')}
            style={{ width: 200 }}
            required
        >
            <MultiSelect
                filterable={false}
                className="small"
                disabled={disabled}
                allowClear={false}
                popupClassName="small"
                style={{ width: 200 }}
                popupWidth={200}
                value={[]}
                onChange={() => { }}
                options={options.map(rbc => ({
                    value: rbc.name,
                    label: rbc.name
                }))}
                placeholder={t('election:institution')}
            />
        </Form.Item>
    )
}

export default MultipleRbcFilter