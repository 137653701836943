import { Form } from 'antd'
import { useElections } from 'api'
import MultiSelect from 'components/molecules/MultiSelect'
import { ElectionType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type MultipleRbcElectionFilterProps = {
    disabled?: boolean
    bahaiYear?: number
}

function MultipleRbcElectionFilter({ bahaiYear, disabled }: MultipleRbcElectionFilterProps) {
    const { t } = useTranslation()

    const { data: rbcElections } = useElections({ bahaiYear: bahaiYear || 0, type: ElectionType.RBC_ELECTION },
        !!bahaiYear)

    const options = useMemo(() => {
        if (!rbcElections) return []

        const sortedElections = [...rbcElections].sort((a, b) => {
            const regionA = a.region.rbc.toLowerCase()
            const regionB = b.region.rbc.toLowerCase()

            return regionA.localeCompare(regionB)
        })

        return sortedElections
    }, [rbcElections])

    return (
        <>
            {!!options && <Form.Item
                name="election"
                className="no-padding"
                label={t('election:institution')}
                style={{ width: 200 }}
                required
            >
                <MultiSelect
                    filterable={false}
                    className="small"
                    disabled={disabled}
                    allowClear={false}
                    popupClassName="small"
                    style={{ width: 200 }}
                    popupWidth={200}
                    value={[]}
                    onChange={() => { }}
                    options={options.map(election => ({
                        value: election.id.toString(),
                        label: election.region.rbc
                    }))}
                    placeholder={t('election:institution')}
                />
            </Form.Item>}
        </>

    )
}

export default MultipleRbcElectionFilter